.toast {
  position: fixed;
  right: -100%;
  top: 20px;
  min-width: 250px;
  margin-right: 20px;
  padding: 12px 24px;
  border-radius: 4px;
  color: white;
  background-color: green;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.show {
  right: 0;
}

.toast.toast-success {
  background-color: green;
}

.toast.toast-error {
  background-color: red;
  color: white;
}

.toast.toast-warning {
  background-color: yellow;
}

.toast.toast-warning {
  color: white;
}


